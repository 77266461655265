<!--
 * @Author: zhoutao mrzater@163.com
 * @Date: 2024-07-08 10:55:35
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-11-14 11:08:31
 * @FilePath: /mediatom-web/src/viewsForManage/dashboard/components/homeTitle.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="home_title">
    <span class="title">{{ title }}</span>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      default: '',
      type: String
    }
  }
}
</script>

<style lang="less" scoped>
.home_title {
  box-sizing: border-box;
  position: relative;
  font-family: PingFang SC, PingFang SC;
  .title {
    font-size: calc(15em / 14)!important;
    font-weight: 600;
    border-radius: calc(10em / 14);
    position: relative;
    cursor: pointer;
    color: #344563;
    margin-right: calc(4em / 14);
    padding-left: calc(10em / 14);
    &::after {
      content: '';
      width: calc(5em / 14);
      height: calc(10em / 14);
      background: #6554C0;
      position: absolute;
      top: calc(4.5em / 14);
      left: 0;
      border-radius: calc(99em / 14);
    }
  }
}
</style>
