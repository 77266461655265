<!--
 * @Author: zhoutao mrzater@163.com
 * @Date: 2024-11-06 14:48:39
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-11-20 18:02:36
 * @FilePath: /mediatom-web/src/viewsForManage/dashboard/models/DataRank/DataChart.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="data-chart">
    <Chart :option="option"/>
  </div>
</template>

<script>
import Chart from '@/components/common/Chart'
import { barOption, pieOption } from './option'
import { targetList } from './typeList'
import { numFormat } from '@/utils/dealNumber'
import mixRateAndMoneyMethod from '@/mixins/rateAndMoneyMethod'
import * as echarts from 'echarts'
export default {
  name: 'DataChart',
  components: { Chart },
  mixins: [mixRateAndMoneyMethod],
  data () {
    return {
      option: {}
    }
  },
  props: {
    target: {
      type: String,
      default: undefined
    }
  },
  methods: {
    handleDealData (data, type) {
      if (type === 'bar') {
        // 柱状图
        this.getBarChartOption(data)
      } else {
        // 扇形图
        this.getPieChartOption(data)
      }
    },
    // 处理柱状图配置
    getBarChartOption (data) {
      const opt = JSON.parse(JSON.stringify(barOption))
      const { list = [] } = data
      const nameList = list.map((item) => item.name)
      opt.xAxis.data = nameList
      opt.series[0].data = list.map((item) => item[this.target])
      opt.series[0].name = this.target
      const name = targetList.find((item) => item.id === this.target).name
      opt.tooltip.formatter = (params) => {
        let value = params.value
        if (params.seriesName === 'request') {
          value = numFormat(params.value, 3, '')
        } else if (this.isMoney(params.seriesName)) {
          value = this.moneyText + numFormat(params.value, 3, '')
        } else if (this.isRota(params.seriesName)) {
          value = numFormat(value, 3, '') + '%'
        } else {
          value = numFormat(value, 3, '')
        }
        return (
          params.marker + params.name +
          '<br/>&nbsp;&nbsp;&nbsp;' + `${ name }` + '：' + value
        )
      }
      opt.series[0].itemStyle = {
        borderWidth: 2,
        color: (params) => {
          const colorList = [
            '#6DABE6',
            '#FF8A8A',
            '#FFAF65',
            '#64C8BC',
            '#7161C5',
            '#FFC5C5'
          ]
          return colorList[params.dataIndex]
        }
      }
      opt.yAxis.axisLabel.formatter = (value, index) => {
        if (value > 1000000) {
          return numFormat(value / 1000000, 3, '') + 'M'
        } else if (value > 1000) {
          return numFormat(value / 1000, 3, '') + 'k'
        } else {
          return value
        }
      }
      this.option = opt
    },
    // 处理扇形图配置
    getPieChartOption (data) {
      const opt = JSON.parse(JSON.stringify(pieOption))
      const name = targetList.find((item) => item.id === this.target).name
      const sum = targetList.find((item) => item.id === this.target).sum
      const { list = [] } = data
      opt.tooltip.formatter = (params) => {
        const str = params.marker + `${ params.name || '其他' }` +
          '<br/>&nbsp;&nbsp;&nbsp;' + `${ name }` + '：' +
          (this.isMoney(this.target) ? this.moneyText : '') + numFormat(params.data.value, 3, '') +
          '<br/>&nbsp;&nbsp;&nbsp;占比：' +
          (!params.data.value ? 0 : sum !== 0 ? ((params.data.value / (data[sum] || 1)) * 100).toFixed(2) : 0) +
          '%'
        return (
          str
        )
      }
      opt.series[0].label.normal.formatter = name
      opt.series[0].itemStyle = {
        borderRadius: [10, 10, 10, 10],
        borderColor: '#fff',
        borderWidth: 6,
        color: (params) => {
          const colorList = [
            {
              c1: 'rgba(183, 105, 247, 1)',
              c2: 'rgba(138, 89, 251, 1)'
            },
            {
              c1: 'rgba(95, 233, 196, 1)',
              c2: 'rgba(62, 219, 223, 1)'
            },
            {
              c1: 'rgba(249, 169, 110, 1)',
              c2: 'rgba(248, 123, 104, 1)'
            },
            {
              c1: 'rgba(250, 84, 124, 1)',
              c2: 'rgba(247, 65, 108, 1)'
            },
            {
              c1: 'rgba(22, 178, 246, 1)',
              c2: 'rgba(31, 125, 238, 1)'
            },
            {
              c1: 'rgba(120, 166, 234, 1)',
              c2: 'rgba(120, 146, 214, 1)'
            }
          ]
          return new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              // 左、下、右、上
              offset: 0,
              color: colorList[params.dataIndex].c1
            },
            {
              offset: 1,
              color: colorList[params.dataIndex].c2
            }
          ])
        }
      }
      if (list.length > 0) list[list.length - 1].name = '其他'
      opt.series[0].data = list
        ? list.map((item, i) => {
            return {
              value: item[this.target],
              name: item.name
            }
          })
        : [
            {
              value: 0,
              name: ''
            }
          ]
      this.option = opt
    }
  }
}
</script>

<style lang="less" scoped>
.data-chart{
  position: relative;
  padding: 0 @smallMargin;
  height: 100%;
}
</style>
