/*
 * @Author: zhoutao mrzater@163.com
 * @Date: 2024-11-20 14:05:19
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-11-20 14:34:16
 * @FilePath: /mediatom-web/src/mixins/observer.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE\
 */

export default {
  data () {
    return {
      option: {
      },
      io: null
    }
  },
  deactivated () {
    this.io && this.io.disconnect()
  },
  methods: {
    watchObserver (callback, dom, options) {
      this.io = new IntersectionObserver(callback, options = this.option)
      this.io.observe(dom)
    },
    observerCommonCallback (callback) {
      return (entries) => {
        entries.forEach(async (entry) => {
          if (entry.isIntersecting) {
            console.log(entries)
            // 目标元素出现在 root 可视区，返回 true
            await callback(entries)
            const $target = entry.target
            this.io.unobserve($target) // 解除观察
          }
        })
      }
    }
  }
}
