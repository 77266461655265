/*
 * @Author: zhoutao mrzater@163.com
 * @Date: 2024-11-05 14:13:30
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-11-14 10:34:52
 * @FilePath: /mediatom-web/src/viewsForManage/dashboard/models/DataRank/typeList.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export const typeList = [
  {
    name: '柱形图',
    id: 'bar'
  },
  {
    name: '饼状图',
    id: 'piev'
  },
  {
    name: '列表',
    id: 'row'
  }
]

// show 0 全部显示  1 超管及其子账号  2 其他用户
export const targetList = [
  { name: '流量请求', id: 'request', sum: 'sumRequest', show: 0 },
  { name: '流量填充', id: 'response', sum: 'sumResponse', show: 0 },
  { name: '流量展示', id: 'impress', sum: 'sumImpress', show: 0 },
  { name: '流量点击', id: 'click', sum: 'sumClick', show: 0 },
  { name: '流量eCPM', id: 'ecpm', sum: 'sumEcpm', show: 0 },
  { name: '利润', id: 'profit', sum: 'sumProfit', show: 0 },
  { name: 'QPS费用', id: 'qpsPrice', sum: 'sumQpsPrice', show: 1 },
  { name: '开放流量', id: 'openPublisherPrice', sum: 'sumOpenPublisherPrice', show: 1 },
  { name: '开放预算', id: 'openDspPrice', sum: 'sumOpenDspPrice', show: 1 },
  { name: '广告请求', id: 'advRequest', sum: 'sumAdvRequest', show: 0 },
  { name: '广告填充', id: 'advResponse', sum: 'sumAdvResponse', show: 2 },
  { name: '广告展示', id: 'advImpress', sum: 'sumAdvImpress', show: 2 },
  { name: '广告点击', id: 'advClick', sum: 'sumAdvClick', show: 2 },
  { name: '广告eCPM', id: 'advEcpm', sum: 'sumAdvEcpm', show: 2 },
  { name: '广告收入', id: 'advPrice', sum: 'sumAdvPrice', show: 2 }
]
