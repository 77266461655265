<!--
 * @Author: zhoutao mrzater@163.com
 * @Date: 2024-11-06 14:48:39
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-11-13 19:49:05
 * @FilePath: /mediatom-web/src/viewsForManage/dashboard/models/DataRank/DataChart.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="data-row">
    <div class="row-list">
      <div class="row-head">
        <div class="name">{{ typeName }}</div>
        <div class="value">{{ targetLabel }}</div>
        <div class="ratio">占比</div>
      </div>
      <div class="list-wrapper">
        <div class="row-item" v-for="(item, index) in dataList" :key="index">
          <div class="name text-ellipsis" :title="item.name">{{ item.name }}</div>
          <div class="value">
            <span v-if="isMoney(item.prop)">{{ moneyText }}</span>
            <span>{{ numFormat(item.value || 0, 3, '') }}</span>
          </div>
          <div class="ratio">
            <span>{{ item.ratio || 0 }}%</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { numFormat } from '@/utils/dealNumber'
import mixRateAndMoneyMethod from '@/mixins/rateAndMoneyMethod'
import { targetList } from './typeList'
export default {
  name: 'DataRow',
  mixins: [mixRateAndMoneyMethod],
  data () {
    return {
      dataList: [],
      typeName: undefined
    }
  },
  props: {
    target: {
      default: undefined,
      type: String
    }
  },
  computed: {
    targetLabel () {
      return targetList.find((item) => item.id === this.target)?.name
    }
  },
  methods: {
    numFormat,
    handleDealData (data) {
      const { list = [], type = 1 } = data
      this.typeName = type === 1 ? '供应商' : type === 2 ? '平台' : '账户'
      const sumProp = targetList.find((item) => item.id === this.target)?.sum
      const sum = data[sumProp] || 1
      this.dataList = list.map((item) => {
        return {
          value: item[this.target] || 0,
          ratio: (((item[this.target] || 0) / sum) * 100).toFixed(2),
          name: item.name
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.data-row{
  font-size: calc(13em / 14);
  color: #5A607F;;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  .row-list {
    height: 100%;
    display: flex;
    flex-direction: column;
    .row-head {
      height: calc(38em / 14);
      line-height: calc(38em / 14);
      width: 100%;
      display: flex;
      font-size: 1em;
      font-weight: 500;
      background-color: #faf8ff;
    }
    .list-wrapper {
      flex: 1 auto;
      display: flex;
      flex-direction: column;
    }
    .list-wrapper, .row-head{
      padding: 0 @smallMargin;
    }
    .row-head,
    .row-item {
      .name {
        width: 40%;
        text-align: left;
      }
      .value {
        flex: 1 auto;
        text-align: left;
      }
      .ratio {
        width: 23%;
        text-align: left;
        display: flex;
        align-items: center;
      }
    }
    .row-item {
      display: flex;
      flex: 1 auto;
      line-height: 100%;
      align-items: center;
      .value {
        font-weight: 500;
      }
    }
  .list-wrapper {
    flex: 1 auto;
    display: flex;
    flex-direction: column;
    .row-item {
      border-bottom: 1px solid rgba(21, 34, 50, 0.15);
      &:last-child {
        border: none;
      }
    }
  }

  }
}
</style>
